function setEqualHeight() {
    $(".grid-menu-item .grid-element-content-detail").matchHeight();
}
$(window).load(function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        scroll_limit = 40,
        initial_height = 100,
        initial_padding = 15,
        breakpoint = 6,
        header_height,
        padding_top = 0;
    margin_top = 0;
    switch (current_breakpoint) {
        case "large":
            scroll_limit = 35;
            initial_height = 75;
            breakpoint = 4;
            break;
        case "medium":
            scroll_limit = 0;
            initial_height = 75;
            breakpoint = 3;
            break;
        case "small":
            scroll_limit = 0;
            initial_height = 75;
            breakpoint = 3;
            break;
        case "xsmall":
            scroll_limit = 0;
            initial_height = 75;
            breakpoint = 3;
            break;
        default:
            scroll_limit = 40;
            initial_height = 100;
            breakpoint = 6;
    }
    if (breakpoint > 3) {
        if ($(window).scrollTop() <= scroll_limit) {
            header_height = initial_height - $(window).scrollTop();
            margin_top = -$(window).scrollTop();
            padding_top = $(window).scrollTop();
        } else {
            header_height = initial_height - scroll_limit;
            margin_top = -scroll_limit;
            padding_top = scroll_limit;
        }
        $("#logo a").css("height", header_height + "px");
        $("#logo").css("padding-top", padding_top + "px");
        $("#header-wrapper").css("margin-top", margin_top + "px");
        // $("#logo-wrapper a").css("padding-top", padding + "px");
        // $("#logo-wrapper a").css("padding-bottom", padding + "px");
    }
});
